var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-stepper",
        {
          model: {
            value: _vm.e1,
            callback: function ($$v) {
              _vm.e1 = $$v
            },
            expression: "e1",
          },
        },
        [
          _vm._l(_vm.param.dadosDocumentos.tiposDocumentos, function (item, i) {
            return _c("div", {
              key: i,
              staticClass: "categorias cursor-pointer",
            })
          }),
          _c(
            "div",
            [
              _c(
                "p",
                { staticClass: "primary--text text-h6 font-weight-regular" },
                [
                  _vm._v(
                    "Veja abaixo a relação de documentos que podem comprovar a "
                  ),
                  _c("strong", [_vm._v(_vm._s(_vm.grupoAtivo.grupo))]),
                  _vm._v(": "),
                ]
              ),
              _c(
                "div",
                { staticClass: "lista" },
                _vm._l(_vm.grupoAtivo.documentos, function (documento, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "item" },
                    [
                      documento.visivel
                        ? _c(
                            "div",
                            { staticClass: "d-flex align-center py-3" },
                            [
                              _c(
                                "div",
                                { staticClass: "icon mr-2" },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { small: "", color: "grey" } },
                                    [_vm._v("mdi-information-outline")]
                                  ),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "text-body-1" }, [
                                _vm._v(" " + _vm._s(documento.titulo) + " "),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _c("v-divider"),
                    ],
                    1
                  )
                }),
                0
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "secundaryBtn--text mt-3",
                          attrs: { depressed: "", color: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.dialog = true
                            },
                          },
                        },
                        [_vm._v(" Anexar documento + ")]
                      ),
                      _c("div", { staticClass: "text-caption mt-2" }, [
                        _vm._v(" Documentos aceitos: png, jpg, jpeg, pdf"),
                        _c("br"),
                        _vm._v(" Tamanho máximo: 5MB "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px", color: "white" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h6" }, [
                _vm._v(" Selecione o documento que será anexado "),
              ]),
              _c(
                "v-card-text",
                { staticClass: "lista" },
                _vm._l(_vm.grupoAtivo.documentos, function (documento, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "item",
                      on: { click: _vm.openUpload },
                    },
                    [
                      documento.visivel
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-center align-center justify-space-between cursor-pointer py-2",
                            },
                            [
                              _c("div", { staticClass: "text-body-1" }, [
                                _vm._v(" " + _vm._s(documento.titulo) + " "),
                              ]),
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mx-2",
                                  attrs: {
                                    width: "20",
                                    color: "grey darken-4",
                                    medium: "",
                                    right: "",
                                  },
                                },
                                [_vm._v(" mdi-chevron-right ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-divider"),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("input", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false",
          },
        ],
        ref: "fileUpload",
        attrs: { type: "file", accept: "image/*,application/pdf" },
        on: { change: _vm.handleUpload },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }