var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { color: "primary", dark: "", fixed: "" } },
        [
          _c(
            "v-container",
            { staticClass: "d-flex align-center pa-0" },
            [
              _vm.step === "inicio"
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mr-3",
                      attrs: { small: "", icon: "", dark: "" },
                      on: { click: _vm.closeDialog },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  )
                : _c(
                    "v-btn",
                    {
                      staticClass: "mr-3",
                      attrs: { small: "", icon: "", dark: "" },
                      on: {
                        click: function ($event) {
                          _vm.step = "inicio"
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                    1
                  ),
              _c("v-toolbar-title", { staticClass: "mt-1" }, [
                _c("div", [
                  _c(
                    "h1",
                    {
                      staticClass: "title font-weight-bold mb-1",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" " + _vm._s(_vm.exibirTitulo) + " ")]
                  ),
                  _c(
                    "h3",
                    {
                      staticClass: "caption ma-0",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" " + _vm._s(_vm.param.beneficiario.nome) + " ")]
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "header-height" }),
      _c(
        "v-container",
        { staticClass: "pa-5 pt-0" },
        [
          _c(
            "v-stepper",
            {
              staticClass: "elevation-0",
              model: {
                value: _vm.step,
                callback: function ($$v) {
                  _vm.step = $$v
                },
                expression: "step",
              },
            },
            [
              _c(
                "v-stepper-items",
                [
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "inicio" } },
                    _vm._l(
                      _vm.param.dadosDocumentos.tiposDocumentos,
                      function (item, i) {
                        return _c(
                          "div",
                          {
                            key: i,
                            staticClass: "categorias cursor-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.mostraDocumentos(i)
                              },
                            },
                          },
                          [_c("navegacao-grupo", { attrs: { item: item } })],
                          1
                        )
                      }
                    ),
                    0
                  ),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "detalhes" } },
                    [
                      _vm.grupoAtivo.grupo
                        ? _c(
                            "p",
                            {
                              staticClass:
                                "primary--text text-h6 font-weight-regular",
                            },
                            [
                              !_vm.grupoAtivo.habilitadoUpload
                                ? _c("span", [
                                    _vm._v(
                                      "Veja os documentos relacionados a "
                                    ),
                                    _c("strong", [
                                      _vm._v(_vm._s(_vm.grupoAtivo.grupo)),
                                    ]),
                                  ])
                                : _vm.grupoAtivo.grupo === "Elegibilidade" ||
                                  _vm.grupoAtivo.grupo.indexOf("Legal") > -1
                                ? _c("span", [
                                    _vm._v(
                                      " Veja abaixo a relação de documentos que podem comprovar a "
                                    ),
                                    _c("strong", [
                                      _vm._v(_vm._s(_vm.grupoAtivo.grupo)),
                                    ]),
                                  ])
                                : _c("span", [
                                    _vm._v("Veja quais os documentos de "),
                                    _c("strong", [
                                      _vm._v(_vm._s(_vm.grupoAtivo.grupo)),
                                    ]),
                                    _vm._v(" que podem ser anexados:"),
                                  ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._l(
                        _vm.grupoAtivo.documentos,
                        function (documento, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "item" },
                            [
                              _c("detalhes-documentos", {
                                attrs: { documento: documento },
                              }),
                            ],
                            1
                          )
                        }
                      ),
                      _c(
                        "v-row",
                        [
                          _vm.grupoAtivo.grupo != null &&
                          _vm.grupoAtivo.habilitadoUpload &&
                          _vm.param.anexarArquivos
                            ? _c(
                                "v-col",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mt-3 secundaryBtn--text",
                                      attrs: {
                                        depressed: "",
                                        color: "primary",
                                        disabled:
                                          _vm.isDisableButtonAnexarDocumento,
                                      },
                                      on: { click: _vm.validaUpload },
                                    },
                                    [_vm._v(" Anexar documento + ")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "text-caption mt-2" },
                                    [
                                      _vm._v(
                                        " Documentos aceitos: png, jpg, jpeg, pdf"
                                      ),
                                      _c("br"),
                                      _vm._v(" Tamanho máximo: 5MB "),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _vm.grupoAtivo !== null
                            ? _c(
                                "v-col",
                                [
                                  _c("arquivos", {
                                    attrs: {
                                      items: _vm.grupoAtivo,
                                      showBotao: _vm.param.showBotao,
                                      excluirArquivos:
                                        _vm.grupoAtivo.habilitadoUpload &&
                                        this.param.etapaProposta !==
                                          "aguardando_aceite_anexos" &&
                                        this.param.etapaProposta !==
                                          "aguardando_assinatura_adendo",
                                    },
                                    on: {
                                      "update:items": function ($event) {
                                        _vm.grupoAtivo = $event
                                      },
                                      excluirDocumento: _vm.excluir,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "3" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mt-3 secundaryBtn--text",
                                  attrs: {
                                    depressed: "",
                                    color: "primary",
                                    block: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.step = "inicio"
                                    },
                                  },
                                },
                                [_vm._v("salvar")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "500px" },
              model: {
                value: _vm.dialog.show,
                callback: function ($$v) {
                  _vm.$set(_vm.dialog, "show", $$v)
                },
                expression: "dialog.show",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "text-h6" }, [
                    _vm._v(" Selecione o documento que será anexado: "),
                  ]),
                  _c(
                    "v-card-text",
                    { staticClass: "lista" },
                    _vm._l(
                      _vm.grupoAtivo.documentos,
                      function (documento, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "item",
                            on: {
                              click: function ($event) {
                                return _vm.openUpload(index)
                              },
                            },
                          },
                          [
                            documento.visivel
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex align-center align-center justify-space-between cursor-pointer py-2",
                                  },
                                  [
                                    _c("div", { staticClass: "text-body-1" }, [
                                      _vm._v(
                                        " " + _vm._s(documento.titulo) + " "
                                      ),
                                    ]),
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mx-2",
                                        attrs: {
                                          width: "20",
                                          color: "grey darken-4",
                                          medium: "",
                                          right: "",
                                        },
                                      },
                                      [_vm._v(" mdi-chevron-right ")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c("v-divider"),
                          ],
                          1
                        )
                      }
                    ),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: {
                width: _vm.isMobile ? "100%" : "600px",
                scrollable: "",
                persistent: "",
              },
              model: {
                value: _vm.dialog.preview,
                callback: function ($$v) {
                  _vm.$set(_vm.dialog, "preview", $$v)
                },
                expression: "dialog.preview",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "div",
                    { staticClass: "pt-5 pl-8 pr-8 pb-5" },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass:
                            "d-flex align-center align-center justify-space-between cursor-pointer",
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.resetEdit()
                                },
                              },
                            },
                            [_vm._v("mdi-undo-variant")]
                          ),
                          _c(
                            "v-icon",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.rotate()
                                },
                              },
                            },
                            [_vm._v("mdi-crop-rotate")]
                          ),
                          _c(
                            "v-icon",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.enabledCrop()
                                },
                              },
                            },
                            [_vm._v("mdi-crop")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-card-text", { staticClass: "lista" }, [
                    _vm.imagemBase64
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-center align-center justify-space-between cursor-pointer py-2",
                          },
                          [
                            _c("vue-cropper", {
                              ref: "cropper",
                              attrs: {
                                viewMode: 0,
                                src: _vm.imagemBase64,
                                autoCrop: _vm.isMobile ? true : false,
                              },
                              on: {
                                ready: function ($event) {
                                  return _vm.initCropper()
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-col",
                        { staticClass: "6" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-3",
                              attrs: {
                                type: "input",
                                color: "error",
                                block: "",
                              },
                              on: { click: _vm.cancelarEnvio },
                            },
                            [_vm._v("Cancelar")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "6" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-3",
                              attrs: {
                                type: "input",
                                color: "success",
                                block: "",
                              },
                              on: { click: _vm.salvarImagem },
                            },
                            [_vm._v("Salvar")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.dialog.pdf
            ? _c("ManipulatePDF", {
                attrs: { dialog: _vm.dialog.pdf, base64: _vm.src },
                on: {
                  savefile: function ($event) {
                    return _vm.salvarArquivo("pdf", $event)
                  },
                  hidedialog: function ($event) {
                    _vm.dialog.pdf = false
                    _vm.src = ""
                  },
                },
              })
            : _vm._e(),
          _c("input", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: false,
                expression: "false",
              },
            ],
            ref: "fileUpload",
            attrs: { type: "file", accept: "image/*,application/pdf" },
            on: { change: _vm.handleUpload },
          }),
        ],
        1
      ),
      _c("base-dialog-details-file-type", {
        attrs: { titulo: _vm.tituloModal, dialog: _vm.dialogDetalhes },
        on: {
          closedialog: function ($event) {
            _vm.dialogDetalhes = false
          },
        },
      }),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: "-1" },
          model: {
            value: _vm.updating.show,
            callback: function ($$v) {
              _vm.$set(_vm.updating, "show", $$v)
            },
            expression: "updating.show",
          },
        },
        [
          _c("v-progress-circular", {
            staticClass: "mr-3",
            attrs: {
              indeterminate: "",
              color: "white",
              size: "20",
              width: "2",
            },
          }),
          _vm._v(" " + _vm._s(_vm.updating.mensagem) + " "),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.showModalCamera,
            callback: function ($$v) {
              _vm.showModalCamera = $$v
            },
            expression: "showModalCamera",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("b", [_vm._v("Qual método deseja usar?")]),
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "secundaryBtn--text",
                              attrs: { color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.selecionarMetodoUpload("upload")
                                },
                              },
                            },
                            [_vm._v("Selecionar arquivo")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "secundaryBtn--text",
                              attrs: { color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.selecionarMetodoUpload("camera")
                                },
                              },
                            },
                            [_vm._v("Câmera")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.mostrandoCamera
        ? _c(
            "div",
            { staticClass: "camera-options" },
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "py-6 secundaryBtn--text",
                          attrs: { color: "primary", rounded: true },
                          on: {
                            click: function ($event) {
                              return _vm.fecharCamera()
                            },
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { size: "50", color: "white" } },
                            [_vm._v("mdi-arrow-left")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "py-6 secundaryBtn--text",
                          attrs: { color: "primary", rounded: true },
                          on: {
                            click: function ($event) {
                              return _vm.capturarCamera()
                            },
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { size: "50", color: "white" } },
                            [_vm._v("mdi-camera-iris")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "py-6 secundaryBtn--text",
                          attrs: { color: "primary", rounded: true },
                          on: {
                            click: function ($event) {
                              return _vm.flipCamera()
                            },
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { size: "50", color: "white" } },
                            [_vm._v("mdi-camera-flip-outline")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }