var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.item
    ? _c(
        "div",
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex align-center justify-space-between categoria py-1",
            },
            [
              _c(
                "div",
                { staticClass: "title font-weight-bold primary--text" },
                [
                  _vm._v(_vm._s(_vm.item.grupo) + " "),
                  _c("div", { staticClass: "text-caption" }, [
                    _vm.docApenasUmObrigatorio[_vm.item.grupo.toLowerCase()]
                      ? _c("p", [_vm._v("(Obrigatório pelo menos 01 anexo)")])
                      : _vm._e(),
                    _vm.taxaAngaricao[_vm.item.grupo.toLowerCase()]
                      ? _c("p", [_vm._v("(Obrigatório pelo menos 01 anexo)")])
                      : _vm._e(),
                    _vm.docOpcionail[_vm.item.grupo.toLowerCase()]
                      ? _c("p", [_vm._v("(Opcional)")])
                      : _vm._e(),
                    _vm.docLeitura[_vm.item.grupo.toLowerCase()]
                      ? _c("p", [_vm._v("(Somente Leitura)")])
                      : _vm._e(),
                    _vm.docReducaoCarencia[_vm.item.grupo.toLowerCase()]
                      ? _c("p", [_vm._v("(Obrigatório)")])
                      : _vm._e(),
                  ]),
                ]
              ),
              _c(
                "div",
                { staticClass: "acoes d-flex align-center" },
                [
                  _c(
                    "v-sheet",
                    {
                      staticClass:
                        "mx-auto rounded-circle primary d-flex justify-center align-center",
                      attrs: { elevation: "0", height: "20", width: "20" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "white--text text-caption font-weight-bold",
                        },
                        [_vm._v(_vm._s(_vm.totalArquivos))]
                      ),
                    ]
                  ),
                  _c(
                    "v-icon",
                    {
                      staticClass: "mx-2",
                      attrs: {
                        width: "20",
                        color: "grey darken-4",
                        medium: "",
                        right: "",
                      },
                    },
                    [_vm._v(" mdi-chevron-right ")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c("v-divider"),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }