var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { "fill-height": "", fluid: "" } },
    [
      _c("v-row", { attrs: { align: "center", justify: "center" } }, [
        _c(
          "div",
          { staticClass: "col-12 col-md-6" },
          [
            _c(
              "v-app-bar",
              { attrs: { color: "primary", dark: "", fixed: "" } },
              [
                _c(
                  "v-container",
                  { staticClass: "d-flex align-center pa-0" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "mr-3",
                        attrs: { small: "", icon: "", dark: "" },
                        on: {
                          click: function ($event) {
                            return _vm.back()
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                      1
                    ),
                    _c("v-toolbar-title", { staticClass: "mt-1" }, [
                      _c("div", [
                        _c(
                          "h1",
                          {
                            staticClass: "title font-weight-bold mb-1",
                            staticStyle: { "line-height": "1" },
                          },
                          [_vm._v(" Validação de Dados ")]
                        ),
                      ]),
                    ]),
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      { attrs: { icon: "" }, on: { click: _vm.reload } },
                      [_c("v-icon", [_vm._v("mdi-reload")])],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "header-height" }),
            _vm.loadingDatas
              ? _c(
                  "div",
                  { staticClass: "d-flex justify-center mt-10 pa-10" },
                  [
                    _c("v-progress-circular", {
                      attrs: { color: "primary", indeterminate: "" },
                    }),
                  ],
                  1
                )
              : _vm.dataDialog &&
                _vm.dataDialog.pendencias &&
                _vm.dataDialog.erros
              ? _c(
                  "div",
                  [
                    _c("pendente", {
                      attrs: {
                        pendenciasPreenchimento: _vm.dataDialog.pendencias,
                        erros: _vm.dataDialog.erros,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.origem == "fluxo"
              ? _c(
                  "v-btn",
                  {
                    staticClass: "text-none mt-4 mb-4",
                    attrs: {
                      large: "",
                      "data-test-id": "validacao-continuar",
                      elevation: "10",
                      block: "",
                      loading: _vm.loading,
                      color: "primary",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.next()
                      },
                    },
                  },
                  [_vm._v("Continuar")]
                )
              : _c(
                  "v-btn",
                  {
                    staticClass: "text-none mt-4 mb-4",
                    attrs: {
                      large: "",
                      elevation: "10",
                      "data-test-id": "validacao-voltar",
                      block: "",
                      loading: _vm.loading,
                      color: "primary",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.back()
                      },
                    },
                  },
                  [_vm._v("Voltar")]
                ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }