var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            persistent: "",
            transition: "dialog-top-transition",
            "max-width": "1000px",
          },
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { dark: "", color: "primary" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: { click: _vm.confirmCloseDialog },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                  _c("v-toolbar-title", [_vm._v("Pré-visualização")]),
                  _c("v-spacer"),
                  _c(
                    "v-toolbar-items",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { dark: "", text: "", disabled: _vm.loading },
                          on: { click: _vm.savePDF },
                        },
                        [_vm._v(" Salvar ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_vm.loading
                ? _c(
                    "div",
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            _vm._l(_vm.completePdf, function (item) {
                              return _c(
                                "v-col",
                                {
                                  key: item.pagina,
                                  attrs: { md: "4", sm: "6", xs: "12" },
                                },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "mx-auto",
                                      class: {
                                        "border-select": _vm.checkPage(
                                          item.pagina
                                        ),
                                      },
                                      attrs: { elevation: "7", width: "300px" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectePage(item.pagina)
                                        },
                                      },
                                    },
                                    [
                                      _vm.checkPage(item.pagina)
                                        ? _c(
                                            "v-sheet",
                                            {
                                              staticClass:
                                                "d-flex align-center justify-center",
                                              staticStyle: {
                                                position: "absolute",
                                                right: "-15px",
                                                top: "-15px",
                                                "border-radius": "100%",
                                                "z-index": "1",
                                              },
                                              attrs: {
                                                color: "green",
                                                width: "50",
                                                height: "50",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    large: "",
                                                    color: "white",
                                                  },
                                                },
                                                [_vm._v(" mdi-check-bold ")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c("v-img", {
                                        attrs: {
                                          src: `data:image/png;base64,${item.base64}`,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-center align-content-center flex-wrap",
                      staticStyle: { "min-height": "320px" },
                    },
                    [
                      _c("v-progress-circular", {
                        attrs: {
                          size: 50,
                          color: "primary",
                          indeterminate: "",
                        },
                      }),
                    ],
                    1
                  ),
              !_vm.loading
                ? _c(
                    "v-footer",
                    {
                      staticClass: "pb-10",
                      staticStyle: { "background-color": "transparent" },
                      attrs: { fixed: "" },
                    },
                    [
                      _c(
                        "v-chip",
                        {
                          staticClass: "mb-10 mx-auto px-4",
                          attrs: { large: "", color: "primary" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                `${_vm.selectedPages.length} / ${_vm.maximoPaginas} selecionadas`
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }