var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticStyle: {
        "border-top-right-radius": "40px",
        "border-bottom-right-radius": "40px",
        overflow: "hidden",
        padding: "7px",
      },
      attrs: { link: "", dark: _vm.dark, flat: "", color: "white" },
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c(
        "v-sheet",
        {
          staticClass: "d-flex flex-column pa-4",
          staticStyle: {
            "border-top-right-radius": "40px",
            "border-bottom-right-radius": "40px",
            position: "relative",
          },
          attrs: {
            link: "",
            elevation: "3",
            dark: _vm.dark,
            color: _vm.dark ? "primary" : "white",
            height: "160",
          },
        },
        [
          _vm.imageUser
            ? _c(
                "v-sheet",
                {
                  staticClass: "d-flex align-center justify-center",
                  staticStyle: {
                    position: "absolute",
                    right: "-2px",
                    top: "-3px",
                    "border-radius": "100%",
                  },
                  attrs: { color: "primary", width: "60", height: "60" },
                },
                [
                  _c("img", {
                    staticClass: "mb-0",
                    attrs: { src: _vm.imageUser, width: "35", height: "35" },
                  }),
                ]
              )
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "subtitle-2 caption",
              class: { "primary--text": !_vm.dark },
            },
            [_vm._v(_vm._s(_vm.type))]
          ),
          _c("v-spacer"),
          _c(
            "span",
            {
              staticClass: "title font-weight-regular text-lowercase name",
              class: { "primary--text": !_vm.dark },
              staticStyle: { "line-height": "1" },
            },
            [_vm._v(_vm._s(_vm.name))]
          ),
          _vm.grauParentesco
            ? _c(
                "span",
                {
                  staticClass: "subtitle-2 caption",
                  class: { "primary--text": !_vm.dark },
                },
                [_vm._v(_vm._s(_vm.grauParentesco))]
              )
            : _vm._e(),
          _c("v-spacer"),
          _c(
            "div",
            {
              staticClass: "subtitle-2 d-flex align-center",
              class: { "primary--text": !_vm.dark },
            },
            [
              _vm._v(" " + _vm._s(_vm.status) + " "),
              _c("v-spacer"),
              _c(
                "v-icon",
                {
                  staticClass: "mr-2",
                  attrs: { size: "20", color: _vm.dark ? "white" : "primary" },
                },
                [_vm._v("mdi-arrow-right")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }