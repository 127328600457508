<template>
<div v-if="item">
  <div class="d-flex align-center justify-space-between categoria py-1">
    <div class="title font-weight-bold primary--text">{{ item.grupo }}
    <div class="text-caption">
    <p v-if="(docApenasUmObrigatorio[item.grupo.toLowerCase()])">(Obrigatório pelo menos 01 anexo)</p>
    <p v-if="(taxaAngaricao[item.grupo.toLowerCase()])">(Obrigatório pelo menos 01 anexo)</p>
    <p v-if="(docOpcionail[item.grupo.toLowerCase()])">(Opcional)</p>
    <p v-if="(docLeitura[item.grupo.toLowerCase()])">(Somente Leitura)</p>
    <p v-if="(docReducaoCarencia[item.grupo.toLowerCase()])">(Obrigatório)</p>
    </div>

    </div>
      <div class="acoes d-flex align-center">
        <v-sheet
          class="mx-auto rounded-circle primary d-flex justify-center align-center"
          elevation="0"
          height="20"
          width="20">
          <div class="white--text text-caption font-weight-bold">{{ totalArquivos }}</div>
        </v-sheet>
        <v-icon
          width="20"
          class="mx-2"
          color="grey darken-4"
          medium
          right>
            mdi-chevron-right
        </v-icon>
      </div>
    </div>
  <v-divider></v-divider>
</div>
</template>

<script>
export default {
  data() {
    return {
      docApenasUmObrigatorio: {
        elegibilidade: true,
        'identificação civil': true,
        'inclusão de dependente': true,
        'identificação do responsavel legal': true,
        dps: true,
      },
      docOpcionail: {
        outros: true,
      },
      docLeitura: {
        'filiação de entidade': true,
        adendo: true,
        'carta de reajuste': true,
      },
      docReducaoCarencia: {
        'reducão de carência': true,
      },
      taxaAngaricao: {
        'Recibo de Taxa de Angariação': true
      }
    };
  },
  name: 'NavegacaoGrupo',
  props: {
    item: Object,
  },
  computed: {
    totalArquivos() {
      let total = 0;
      this.item.documentos.forEach((element) => {
        total += element.arquivos.length;
      });
      return total;
    },
  },
};
</script>
