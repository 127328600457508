var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-container", { staticClass: "pa-5 pb-5 pt-10" }, [
        _c(
          "div",
          { staticClass: "text-center mt-5" },
          [
            _c(
              "v-icon",
              {
                staticStyle: { "font-size": "120px" },
                attrs: { color: "error" },
              },
              [_vm._v("mdi-alert-circle")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "v-container",
        { staticClass: "pa-5 pb-5 pt-5" },
        [
          _c(
            "v-expansion-panels",
            {
              attrs: { multiple: "" },
              model: {
                value: _vm.panel,
                callback: function ($$v) {
                  _vm.panel = $$v
                },
                expression: "panel",
              },
            },
            [
              _c(
                "v-expansion-panel",
                { staticClass: "mb-5" },
                [
                  _c("v-expansion-panel-header", [
                    _c(
                      "h1",
                      {
                        staticClass:
                          "title font-weight-regular mt-4 mb-1 primary--text",
                        staticStyle: { "line-height": "1" },
                      },
                      [
                        _vm._v(
                          " Pendências (" +
                            _vm._s(_vm.pendenciasPreenchimento.length) +
                            ") "
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _vm.pendenciasPreenchimento &&
                      _vm.pendenciasPreenchimento.length == 0
                        ? _c("span", { staticClass: "primary--text" }, [
                            _vm._v("Nenhum item encontrado"),
                          ])
                        : _vm._e(),
                      _vm._l(_vm.pendenciasPreenchimento, function (item, i) {
                        return _c(
                          "div",
                          {
                            key: i,
                            staticClass: "d-flex border-top pt-4 pb-4",
                          },
                          [
                            _c(
                              "v-icon",
                              { attrs: { color: "amber", large: "" } },
                              [_vm._v("mdi-alert-circle")]
                            ),
                            _c(
                              "span",
                              { staticClass: "ml-5 pt-2 primary--text" },
                              [_vm._v(_vm._s(item.mensagem))]
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [
                    _c(
                      "h1",
                      {
                        staticClass:
                          "title font-weight-regular mt-4 mb-1 primary--text",
                        staticStyle: { "line-height": "1" },
                      },
                      [_vm._v(" Erros (" + _vm._s(_vm.erros.length) + ") ")]
                    ),
                  ]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _vm.erros && _vm.erros.length == 0
                        ? _c("span", { staticClass: "primary--text" }, [
                            _vm._v("Nenhum item encontrado"),
                          ])
                        : _vm._e(),
                      _vm._l(_vm.erros, function (item, i) {
                        return _c(
                          "div",
                          {
                            key: i,
                            staticClass: "d-flex border-top pt-4 pb-4",
                          },
                          [
                            _c(
                              "v-icon",
                              { attrs: { color: "red darken-4", large: "" } },
                              [_vm._v("mdi-close-circle")]
                            ),
                            _c(
                              "span",
                              { staticClass: "ml-5 pt-2 primary--text" },
                              [_vm._v(_vm._s(item.mensagem))]
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }