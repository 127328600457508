<template>
  <v-dialog v-model="show" :persistent="persistent" :max-width="width" :id="`modal-${name}`">
    <v-card>
      <v-card-title>
        <h2 class="primary--text mb-2 subtitle-1 font-weight-bold">{{ title }}</h2>
      </v-card-title>
      <v-card-text>
        {{ text }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="buttonCancel" class="mr-1" color="red darken-1" text @click="$emit('close')">
          {{ buttonCancel }}
        </v-btn>
        <v-btn color="primary" text type="submit" :loading="loading" @click="$emit('confirm')">
          {{ buttonConfirm }}
        </v-btn>
      </v-card-actions>
    </v-card>

    </v-dialog>
</template>

<script>
export default {
  props: {
    buttonCancel: {
      default: 'cancelar',
    },
    buttonConfirm: {
      default: 'confirmar',
    },
    show: {
      required: true,
      type: Boolean,
    },
    loading: {
      required: true,
    },
    name: {
      required: true,
      type: String,
    },
    width: {
      required: false,
      default: '400px',
    },
    text: {
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    persistent: {
      required: false,
      default: false,
    },
  },
};
</script>
