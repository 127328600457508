var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "400" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "title primary white--text pt-1 pb-1" },
                [
                  _c("span", [_vm._v(" Detalhe documento ")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text",
                      attrs: {
                        elevation: "0",
                        fab: "",
                        dark: "",
                        color: "primary",
                        small: "",
                        right: "",
                        absolute: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("closedialog")
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                {
                  staticClass: "black--text text-center d-table pb-1 pt-1",
                  staticStyle: { height: "200px" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-table-cell",
                      staticStyle: { "vertical-align": "middle" },
                    },
                    [_vm._v(" " + _vm._s(_vm.titulo) + " ")]
                  ),
                ]
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "font-weight-bold",
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("closedialog")
                        },
                      },
                    },
                    [_vm._v(" Entendi ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }