<template>
  <v-card
    link
    :dark="dark"
    flat
    color="white"
    style="border-top-right-radius: 40px; border-bottom-right-radius: 40px; overflow: hidden; padding: 7px"
    @click="$emit('click')"
  >
    <v-sheet
      link
      elevation="3"
     :dark="dark"
      :color="dark ? 'primary' : 'white'"
      class="d-flex flex-column pa-4"
      style="border-top-right-radius: 40px; border-bottom-right-radius: 40px; position: relative"
      height="160"
    >
      <v-sheet
      v-if="imageUser"
        color="primary"
        class="d-flex align-center justify-center"
        style="position: absolute; right: -2px;top: -3px; border-radius: 100%"
        width="60"
        height="60"
      >
        <img :src="imageUser" width="35" height="35" class="mb-0" />
      </v-sheet>
      <span class="subtitle-2 caption" :class="{'primary--text': !dark}">{{type}}</span>
      <v-spacer></v-spacer>
      <span class="title font-weight-regular text-lowercase name" style="line-height: 1" :class="{'primary--text': !dark}">{{name}}</span>
      <span v-if="grauParentesco" class="subtitle-2 caption"
        :class="{'primary--text': !dark}">{{grauParentesco}}</span>
      <v-spacer></v-spacer>
      <div class="subtitle-2 d-flex align-center" :class="{'primary--text': !dark}">
        {{status}}
        <v-spacer></v-spacer>
        <v-icon size="20" :color="dark ? 'white' : 'primary'" class="mr-2">mdi-arrow-right</v-icon>
      </div>
    </v-sheet>
  </v-card>
</template>

<script>
import Grandma from '@/assets/img/family/grand-mother.png';
import Woman from '@/assets/img/family/woman.png';
import Girl from '@/assets/img/family/girl.png';
import Baby from '@/assets/img/family/baby.png';
import GrandFather from '@/assets/img/family/grand-father.png';
import Man from '@/assets/img/family/man.png';
import Boy from '@/assets/img/family/boy.png';

export default {
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    grauParentesco: {
      type: String,
      default: '',
    },
    age: {
      type: Number,
    },
    gender: {
      type: String,
    },
  },
  data() {
    return {
      man: {
        baby: Baby,
        young: Boy,
        adult: Man,
        old: GrandFather,
      },
      woman: {
        baby: Baby,
        young: Girl,
        adult: Woman,
        old: Grandma,
      },
    };
  },
  computed: {
    imageUser() {
      if (!this.gender) return false;
      if (this.gender && this.gender.toUpperCase() === 'MASCULINO') {
        if (this.age <= 2) {
          return this.man.baby;
        }
        if (this.age > 2 && this.age < 19) {
          return this.man.young;
        }
        if (this.age >= 19 && this.age < 65) {
          return this.man.adult;
        }
        return this.man.old;
      }
      if (this.age <= 2) {
        return this.woman.baby;
      }
      if (this.age > 2 && this.age < 19) {
        return this.woman.young;
      }
      if (this.age >= 19 && this.age < 65) {
        return this.woman.adult;
      }
      return this.woman.old;
    },
  },
};
</script>

<style scoped>
  .name::first-letter {
    text-transform: capitalize;
  }
</style>
