import api from '@/services/api';
import AppError from '@/utils/appError';

const getDocumentosPorParticipante = async ({
  guidProposta,
}) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PROPOSTA_API_KEY } };
    const url = `propostas/${guidProposta}/documentos`;
    const { data } = await api.get(url, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response);
  }
};

const adicionarDocumento = async ({
  guidProposta, guidTipoDocumento, arquivos, pagesSelected,
}) => {
  try {
    const body = {
      guidTipoDocumento,
      arquivos,
      paginasSelecionadas: pagesSelected,
      origem: "CORRETOR",
      validaDocumento: true
    };
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PROPOSTA_API_KEY } };
    const { data } = await api.post(`propostas/${guidProposta}/documentos`, body, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const excluirDocumento = async ({
  guidProposta,
  guidParticipante,
  arquivos,
}) => {
  const body = {
    arquivos,
  };
  try {
    // /propostas/:guidProposta/documentos/pessoa/:guidParticipante/excluiArquivos
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PROPOSTA_API_KEY } };
    const { data } = await api.post(`propostas/${guidProposta}/documentos/pessoa/${guidParticipante}/excluiArquivos`, body, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const tratarExibicaoAnexo = async (payload) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PROPOSTA_API_KEY } };
    const { data } = await api.post(`propostas/documentos/tratarExibicaoAnexoLista`, payload, config);
    return data && data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response);
  }
};

/*
  /propostas/:guidProposta/documentos/pessoa/:guidParticipante/excluiArquivos
POST - /qvenda/propostas/:guidProposta/documentos
{
    "guidTipoDocumento": "5bddce70-ea7d-461d-b44f-9f983e802942",
    "arquivos": [
        {
            "stream": "fasjdhjashjasd",
            "extensao": ".jpg",
            "observacao": ""
        }
    ]
}
*/
export default {
  getDocumentosPorParticipante,
  adicionarDocumento,
  excluirDocumento,
  tratarExibicaoAnexo,
};
