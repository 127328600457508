<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="400"
    >

      <v-card>
        <v-card-title class="title primary white--text pt-1 pb-1">
          <span>
            Detalhe documento
          </span>
          <v-spacer></v-spacer>
          <v-btn elevation="0" fab dark color="primary" class="white--text" small right absolute @click="$emit('closedialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="black--text text-center d-table pb-1 pt-1" style="height: 200px;!important;">
          <div class="d-table-cell" style="vertical-align: middle;">
            {{ titulo }}
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="font-weight-bold"
            color="primary"
            text
            @click="$emit('closedialog')"
          >
            Entendi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  props: {
    dialog: Boolean,
    titulo: String,
  },
};
</script>

<style>
</style>
