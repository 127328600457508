<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <div class="col-12 col-md-6">
        <v-app-bar color="primary" dark fixed>
          <v-container class="d-flex align-center pa-0">
            <v-btn class="mr-3" small icon dark @click="back()">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title class="mt-1">
              <div>
                <h1 class="title font-weight-bold mb-1" style="line-height: 1">
                  Validação de Dados
                </h1>
                <!-- <h3 class="caption ma-0" style="line-height: 1">
              Transmitir Proposta
            </h3> -->
              </div>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="reload">
              <v-icon>mdi-reload</v-icon>
            </v-btn>
          </v-container>
        </v-app-bar>

        <div class="header-height"></div>

        <div v-if="loadingDatas" class="d-flex justify-center mt-10 pa-10">
          <v-progress-circular
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>

        <div
          v-else-if="dataDialog && dataDialog.pendencias && dataDialog.erros"
        >
          <pendente
            :pendenciasPreenchimento="dataDialog.pendencias"
            :erros="dataDialog.erros"
          />
        </div>

        <v-btn
          large
          :data-test-id="'validacao-continuar'"
          elevation="10"
          block
          :loading="loading"
          color="primary"
          class="text-none mt-4 mb-4"
          @click="next()"
          v-if="origem == 'fluxo'"
          >Continuar</v-btn
        >
        <v-btn
          large
          elevation="10"
          :data-test-id="'validacao-voltar'"
          block
          :loading="loading"
          color="primary"
          class="text-none mt-4 mb-4"
          @click="back()"
          v-else
          >Voltar</v-btn
        >
      </div>
    </v-row>
  </v-container>
</template>

<script>
import propostaService from "@/services/propostaService";
import { mapState } from "vuex";
import Pendente from "./Pendente.vue";

export default {
  name: "ValidacaoDadosDialog",
  props: {
    data: Object,
    origem: String,
  },
  components: {
    Pendente,
  },
  data() {
    return {
      loadingDatas: false,
      dataDialog: null,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      propostaState: (state) => state.cadastroProposta.proposta,
    }),
  },
  created() {
    this.dataDialog = this.data;
    this.loading = false;
  },
  methods: {
    async reload() {
      try {
        this.loadingDatas = true;
        const { data } = await propostaService.validarEnvioAcessoCliente({
          guidProposta: this.propostaState.guid,
        });
        this.dataDialog = data;
        this.loadingDatas = false;
      } catch (error) {
        this.loadingDatas = false;
        this.$root.$snackBar.open({
          color: "error",
          message: "Erro ao validar, tente novamente mais tarde",
        });
      }
    },
    async next() {
      try {
        this.loading = true;
        const { guid: guidProposta } = this.propostaState;
        await this.salvarDados();
        // await propostaService.enviarAcessoCliente({ guidProposta });
        await propostaService.atualizarEtapa({ guidProposta, etapa: 'erro_validacao' });
        this.loading = false;
        this.$emit('next');
      } catch (error) {
        this.loading = false;
        this.$root.$snackBar.open({
          color: 'error',
          message: 'Erro ao enviar, tente novamente mais tarde',
        });
      }
    },
    async back() {
      try {
        if (this.origem === 'fluxo') {
          this.$emit('closedialog');
          return;
        }
        this.loading = true;
        const { guid: guidProposta } = this.propostaState;
        const { data } = await propostaService.validarEnvioAcessoCliente({ guidProposta });

        if (data && !data.prontoParaEnvio) {
          await propostaService.atualizarEtapa({ guidProposta, etapa: 'erro_validacao' });
        }
        this.loading = false;
        this.$emit('closedialog');
      } catch (error) {
        this.loading = false;
        this.$emit('closedialog');
      }
    },
    async salvarDados() {
      const [data] = await propostaService.addOrEdit(this.propostaState.guid, this.propostaState);
    },
  },
};
</script>

<style>
</style>
