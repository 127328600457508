<template>
  <div>
  <div class="d-flex align-center py-3" v-if="documento.visivel">
  <div class="icon mr-2">
      <v-icon v-if="documento.arquivos.length > 0" small color="green">mdi-checkbox-marked-circle-outline</v-icon>
      <v-icon v-else small color="grey">mdi-information-outline</v-icon>
  </div>
  <div class="text-body-1">
      {{ documento.titulo }}
  </div>
  </div>
  <v-divider></v-divider>
</div>
</template>

<script>
export default {
  name: 'DetalhesDocumentos',
  props: {
    documento: Object,
  },
};
</script>
