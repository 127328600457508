var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.items
    ? _c(
        "div",
        [
          _vm.totalArquivos > 0
            ? _c(
                "div",
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-subtitle-1 font-weight-medium primary--text my-4",
                    },
                    [_vm._v("Documentos anexados:")]
                  ),
                  _vm._l(_vm.items.documentos, function (documento, index) {
                    return _c(
                      "div",
                      { key: `doc-${index}` },
                      _vm._l(
                        documento.arquivos,
                        function (arquivo, indexArquivo) {
                          return _c(
                            "div",
                            { key: `arquivo-${indexArquivo}` },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass:
                                    "pa-1 d-flex float-left align-center justify-center mr-2 p-relative mt-1",
                                  staticStyle: { overflow: "hidden" },
                                  attrs: {
                                    elevation: "3",
                                    height: 135,
                                    width: 125,
                                  },
                                },
                                [
                                  arquivo.isAdendo
                                    ? _c(
                                        "div",
                                        { staticClass: "pm-absolute" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-subtitle-2 font-weight-medium primary--text",
                                            },
                                            [_vm._v(" ADENDO ")]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    { staticClass: "p-absolute" },
                                    [
                                      _vm.isVisibleTrashIcon(arquivo)
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: { small: "", icon: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openDialog(
                                                    arquivo.guid
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "red" } },
                                                [_vm._v("mdi-trash-can")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  arquivo.origem
                                    ? _c("div", { staticClass: "b-absolute" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-subtitle-2 font-weight-medium primary--text",
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(arquivo.origem) + " "
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.pdf &&
                                  _vm.pdf[index] &&
                                  _vm.pdf[index][indexArquivo] &&
                                  _vm.pdf[index][indexArquivo].isPDF
                                    ? _c(
                                        "div",
                                        {
                                          class: {
                                            "w-100px":
                                              !!_vm.pdf[index][indexArquivo]
                                                .urlShow,
                                            "h-140px":
                                              !!_vm.pdf[index][indexArquivo]
                                                .urlShow,
                                          },
                                        },
                                        [
                                          _c("v-progress-linear", {
                                            attrs: {
                                              active:
                                                !_vm.pdf[index][indexArquivo]
                                                  .urlShow,
                                              indeterminate:
                                                !_vm.pdf[index][indexArquivo]
                                                  .urlShow,
                                              color: "primary",
                                              absolute: "",
                                              bottom: "",
                                            },
                                          }),
                                          _c(
                                            "v-img",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  position: "top",
                                                  src: `data:image/png;base64,${_vm.pdf[index][indexArquivo].urlShow}`,
                                                  alt: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.zoom(
                                                      arquivo.link,
                                                      arquivo.guid,
                                                      arquivo.habilitadoRemove,
                                                      arquivo.origem
                                                    )
                                                  },
                                                },
                                              },
                                              "v-img",
                                              _vm.$attrs,
                                              false
                                            )
                                          ),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "v-img",
                                        _vm._b(
                                          {
                                            attrs: {
                                              height: _vm.height - 10,
                                              position: "top",
                                              src: arquivo.link,
                                              alt: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.zoom(
                                                  arquivo.link,
                                                  arquivo.guid,
                                                  arquivo.habilitadoRemove,
                                                  arquivo.origem
                                                )
                                              },
                                            },
                                          },
                                          "v-img",
                                          _vm.$attrs,
                                          false
                                        )
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }
                      ),
                      0
                    )
                  }),
                ],
                2
              )
            : _c("div", [
                _c(
                  "div",
                  {
                    staticClass:
                      "text-subtitle-2 font-weight-medium primary--text",
                  },
                  [_vm._v("Nenhum documento anexado")]
                ),
              ]),
          _c(
            "v-dialog",
            {
              model: {
                value: _vm.preview.show,
                callback: function ($$v) {
                  _vm.$set(_vm.preview, "show", $$v)
                },
                expression: "preview.show",
              },
            },
            [
              _c(
                "v-card",
                { attrs: { tile: "", dark: "", color: "black" } },
                [
                  _c(
                    "v-app-bar",
                    { attrs: { color: "black", dark: "", fixed: "" } },
                    [
                      _c(
                        "v-container",
                        { staticClass: "d-flex align-center pa-0 pa-md-4" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-3",
                              attrs: { small: "", icon: "", dark: "" },
                              on: {
                                click: function ($event) {
                                  _vm.preview.show = false
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                            1
                          ),
                          _c("v-toolbar-title", { staticClass: "ml-2" }, [
                            _vm._v("Documento"),
                          ]),
                          _c("v-spacer"),
                          _vm.isVisibleTrashIcon(_vm.preview)
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openDialog(_vm.preview.guid)
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("mdi-delete")])],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticStyle: { height: "70px" } }),
                  _c(
                    "v-container",
                    { staticClass: "d-flex justify-center pa-4" },
                    [
                      _c("v-img", {
                        attrs: {
                          "max-width": "800",
                          width: "100%",
                          src: _vm.preview.file,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "290" },
              model: {
                value: _vm.dialogDelete,
                callback: function ($$v) {
                  _vm.dialogDelete = $$v
                },
                expression: "dialogDelete",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [_vm._v("Remover Anexo")]),
                  _c("v-card-text", [
                    _vm._v("Deseja realmente remover este documento?"),
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "grey darken-1", text: "" },
                          on: {
                            click: function ($event) {
                              _vm.dialogDelete = false
                            },
                          },
                        },
                        [_vm._v("Cancelar")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "grey darken-1", text: "" },
                          on: { click: _vm.excluirItem },
                        },
                        [_vm._v("Remover")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }