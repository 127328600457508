<template>
  <div v-if="items">
    <div v-if="totalArquivos > 0">
      <div class="text-subtitle-1 font-weight-medium primary--text my-4">Documentos anexados:</div>
      <div v-for="(documento, index) in items.documentos" :key="`doc-${index}`">
        <div v-for="(arquivo, indexArquivo) in documento.arquivos" :key="`arquivo-${indexArquivo}`">
          <v-card
            class="pa-1 d-flex float-left align-center justify-center mr-2 p-relative mt-1"
            style="overflow: hidden"
            elevation="3"
            :height="135"
            :width="125"
          >
            <div class="pm-absolute" v-if="arquivo.isAdendo">
              <div class="text-subtitle-2 font-weight-medium primary--text">
                ADENDO
              </div>
            </div>
            <div class="p-absolute">
              <v-btn small icon @click="openDialog(arquivo.guid)" v-if="isVisibleTrashIcon(arquivo)">
                <v-icon color="red">mdi-trash-can</v-icon>
              </v-btn>
            </div>
            <div class="b-absolute" v-if="arquivo.origem">
              <div class="text-subtitle-2 font-weight-medium primary--text">
                {{ arquivo.origem }}
              </div>
            </div>
            <div
              v-if="pdf && pdf[index] && pdf[index][indexArquivo] && pdf[index][indexArquivo].isPDF"
              :class="{ 'w-100px': !!pdf[index][indexArquivo].urlShow, 'h-140px': !!pdf[index][indexArquivo].urlShow}"
            >
              <v-progress-linear
                :active="!pdf[index][indexArquivo].urlShow"
                :indeterminate="!pdf[index][indexArquivo].urlShow"
                color="primary"
                absolute
                bottom
              ></v-progress-linear>
              <v-img
                position="top"
                v-bind="$attrs"
                :src="`data:image/png;base64,${pdf[index][indexArquivo].urlShow}`"
                @click="zoom(arquivo.link, arquivo.guid, arquivo.habilitadoRemove, arquivo.origem)"
                alt
              ></v-img>
            </div>
            <v-img
              v-else
              :height="height - 10"
              position="top"
              v-bind="$attrs"
              :src="arquivo.link"
              @click="zoom(arquivo.link, arquivo.guid, arquivo.habilitadoRemove, arquivo.origem)"
              alt
            ></v-img>
          </v-card>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="text-subtitle-2 font-weight-medium primary--text">Nenhum documento anexado</div>
    </div>
    <v-dialog v-model="preview.show">
    <v-card tile dark color="black">
      <v-app-bar color="black" dark fixed>
        <v-container class="d-flex align-center pa-0 pa-md-4">
          <v-btn class="mr-3" small icon dark @click="preview.show = false">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>

          <v-toolbar-title class="ml-2">Documento</v-toolbar-title>

          <v-spacer></v-spacer>
          <v-btn icon @click="openDialog(preview.guid)"  v-if="isVisibleTrashIcon(preview)" >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-container>
      </v-app-bar>
      <div style="height: 70px"></div>
      <v-container class="d-flex justify-center pa-4">
        <v-img max-width="800" width="100%" :src="preview.file"></v-img>
      </v-container>
    </v-card>
  </v-dialog>
  <v-dialog v-model="dialogDelete" persistent max-width="290">
    <v-card>
      <v-card-title>Remover Anexo</v-card-title>
      <v-card-text>Deseja realmente remover este documento?</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1"  text @click="dialogDelete = false">Cancelar</v-btn>
        <v-btn color="grey darken-1" text @click="excluirItem">Remover</v-btn>
      </v-card-actions>
    </v-card>
    </v-dialog>
  </div>
</template>

<script>
import documentoService from '@/services/documentoService';
import { mapState } from 'vuex';

export default {
  name: 'Arquivos',
  data() {
    return {
      show: [],
      preview: {
        file: null,
        show: false,
      },
      height: 100,
      dialogDelete: false,
      remove: null,
      pdf: [],
    };
  },
  props: {
    items: Object,
    showBotao: Boolean,
    excluirArquivos: Boolean,
  },
  watch: {
    items(newValue) {
      this.insereDadosPdf(newValue);
      this.base64PrimeiraPagina();
    },
  },
  computed: {
    ...mapState({
      propostaState: (state) => state.cadastroProposta.proposta,
    }),
    totalArquivos() {
      let total = 0;
      if (this.items.documentos) {
        this.items.documentos.forEach((element) => {
          total += element.arquivos.length;
        });
      }
      return total;
    },
  },
  methods: {
    isVisibleTrashIcon(arquivo) {
      if (this.propostaState.etapa === 'aguardando_assinatura' && typeof this.propostaState.permiteEditar === 'boolean' &&  !this.propostaState.permiteEditar) {
        return false
      }
      return this.excluirArquivos && arquivo.habilitadoRemove && this.habilitaExcluir(arquivo.origem)
    },
    // resetTooltip() {
    //   const { length } = this.show;
    //   this.show = [];
    //   for (let cont = 0; cont < length; cont++) {
    //     this.show.push(false);
    //   }
    // },
    // trataItems() {
    //   if (this.items && this.items.documentos) {
    //     this.items.documentos.forEach((doc) => {
    //       if (doc && doc.arquivos) {
    //         doc.arquivos.forEach(() => {
    //           this.show.push(false);
    //         });
    //       }
    //     });
    //   }
    // },
    habilitaExcluir(value) {
      return value === "CORRETOR";
    },
    zoom(file, guid, habilitadoRemove, origem) {
      if (file.indexOf('pdf') > 0) {
        window.open(file);
      } else {
        this.preview.file = file;
        this.preview.guid = guid;
        this.preview.show = true;
        this.preview.habilitadoRemove = habilitadoRemove;
        this.preview.origem = origem;
      }
    },
    openDialog(guid) {
      this.remove = guid;
      this.dialogDelete = true;
    },
    excluirItem() {
      this.preview.show = false;
      this.$emit('excluirDocumento', this.remove);
      this.dialogDelete = false;
    },
    async insereDadosPdf(value) {
      this.pdf = [];
      value.documentos.forEach((doc, doci) => {
        this.pdf.push([]);
        doc.arquivos.forEach((arq, arqi) => {
          this.pdf[doci].push({
            link: arq.link,
            urlShow: '',
            isPDF: arq.link.includes('.pdf'),
            guid: arq.guid,
          });
        });
      });
    },
    async base64PrimeiraPagina() {
      const pdfs = [...this.pdf];

      const payload = {
        primeiraPagina: true,
        link: [],
      };

      pdfs.forEach((p) => {
        const pdf = p.filter((item) => item.isPDF);
        pdf.forEach((item) => {
          payload.link.push({
            guid: item.guid,
            valor: item.link,
          });
        });
      });

      const result = await documentoService.tratarExibicaoAnexo(payload);

      pdfs.forEach((p) => {
        const pdf = p.filter((item) => item.isPDF);
        pdf.forEach((item) => {
          const arq = result.find((i) => i.guid === item.guid);
          item.urlShow = arq.paginas.base64;
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.p-relative {
  position: relative;
}
.p-absolute {
  position: absolute;
  z-index: 4;
  right: 2px;
  top: 0px;
}
.pm-absolute {
  position: absolute;
  z-index: 4;
  top: 2px;
}
.b-absolute {
  position: absolute;
  z-index: 4;
  text-align: center;
  bottom: 1px;
}
.icon-pdf {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 36px;
}
.w-100px {
  width: 100px;
}
.h-140px {
  height: 140px;
}
</style>
