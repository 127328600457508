var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: _vm.persistent,
        "max-width": _vm.width,
        id: `modal-${_vm.name}`,
      },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c(
              "h2",
              { staticClass: "primary--text mb-2 subtitle-1 font-weight-bold" },
              [_vm._v(_vm._s(_vm.title))]
            ),
          ]),
          _c("v-card-text", [_vm._v(" " + _vm._s(_vm.text) + " ")]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm.buttonCancel
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mr-1",
                      attrs: { color: "red darken-1", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("close")
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.buttonCancel) + " ")]
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    text: "",
                    type: "submit",
                    loading: _vm.loading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("confirm")
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.buttonConfirm) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }