<template>
  <div>
    <v-dialog
      v-model="showDialog"
      persistent
      transition="dialog-top-transition"
      max-width="1000px"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="confirmCloseDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Pré-visualização</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="savePDF"
              :disabled="loading"
            >
              Salvar
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div v-if="!loading">
          <v-card-text>
            <v-row>
              <v-col
                v-for="item in completePdf"
                :key="item.pagina"
                md="4"
                sm="6"
                xs="12"
              >
                <v-card
                  elevation="7"
                  :class="{'border-select': checkPage(item.pagina)}"
                  @click="selectePage(item.pagina)"
                  class="mx-auto"
                  width="300px"
                >
                  <v-sheet
                    v-if="checkPage(item.pagina)"
                    color="green"
                    class="d-flex align-center justify-center"
                    style="position: absolute; right: -15px;top: -15px; border-radius: 100%; z-index: 1"
                    width="50"
                    height="50"
                  >
                    <v-icon
                      large
                      color="white"
                    >
                      mdi-check-bold
                    </v-icon>
                  </v-sheet>
                  <v-img
                    :src="`data:image/png;base64,${item.base64}`"
                  >
                  </v-img>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </div>
        <div
          v-else
          class="d-flex justify-center align-content-center flex-wrap"
          style="min-height: 320px"
        >
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
        <v-footer
          v-if="!loading"
          fixed
          style="background-color: transparent"
          class="pb-10"
        >
          <v-chip
            large
            color="primary"
            class="mb-10 mx-auto px-4"
          >
            {{
              `${selectedPages.length} / ${maximoPaginas} selecionadas`
            }}
          </v-chip>
        </v-footer>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import documentoService from '@/services/documentoService';

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    base64: {
      type: String,
      default: '',
    },
  },
  mounted() {
    this.showDialog = this.dialog;
    this.streamPdf = this.base64;
  },
  watch: {
    showDialog(newValue) { // Close dialog
      if (!newValue) {
        this.$emit('hidedialog');
      }
    },
    dialog(newValue) {
      this.showDialog = newValue;
    },
    base64(newValue) {
      this.streamPdf = newValue;
    },
    streamPdf(newValue) {
      if (newValue) {
        this.tratarExibicaoAnexo(newValue);
      }
    },
  },
  data() {
    return {
      showDialog: false,
      streamPdf: '',
      completePdf: [],
      maximoPaginas: 5,
      loading: false,
      selectedPages: [],
      limitPages: 20,
    };
  },
  methods: {
    async tratarExibicaoAnexo(stream) {
      try {
        this.loading = true;
        const base64 = stream.split(',')[1];
        const payload = { base64: [{ valor: base64 }] };
        const [data] = await documentoService.tratarExibicaoAnexo(payload);
        this.completePdf = data.paginas;
        this.selectAllPages();
      } catch (error) {
        this.$root.$snackBar.open({ color: 'error', message: error.message });
      } finally {
        this.loading = false;
      }
    },
    selectePage(page) {
      const pageIndex = this.selectedPages.indexOf(page);
      if (pageIndex > -1) {
        this.selectedPages.splice(pageIndex, 1);
      } else if (page) {
        if(this.selectedPages.length === 5)
          this.$root.$snackBar.open({ color: 'error', message: `Limite máximo de páginas é ${this.maximoPaginas}` });
        else
          this.selectedPages.push(page);
      }
    },
    selectAllPages() {
      this.selectedPages = [];
      for (let index = 0; index < this.maximoPaginas; index++) {
        if(this.completePdf[index] && this.completePdf[index].pagina) this.selectedPages.push(this.completePdf[index].pagina);
        
      }
    },
    checkPage(page = 0) {
      const result = this.selectedPages.includes(page);
      return result;
    },
    savePDF() {
      if (!this.selectedPages.length) {
        this.$root.$snackBar.open({ color: 'error', message: 'Necessário selecionar pelo menos 1 página' });
        return;
      }
      if (this.selectedPages.length > this.limitPages) {
        this.$root.$snackBar.open({ color: 'error', message: 'O PDF enviado não ter mais de 20 páginas' });
        return;
      }
      this.$emit('savefile', {
        stream: this.streamPdf.split(',')[1],
        pagesSelected: this.selectedPages,
      });
      this.showDialog = false;
    },
    async confirmCloseDialog() {
      const resp = await this.$root.$confirmdialog.open(
        'Alterações pendentes',
        'Suas alterações no PDF serão descartadas e o arquivo não será anexado. Deseja voltar?',
      );
      if (resp) {
        this.showDialog = false;
      }
    },
  },
};
</script>
<style scoped>

  .border-select {
    border: 2px solid #A5D6A7;
  }

</style>
