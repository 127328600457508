var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.documento.visivel
        ? _c("div", { staticClass: "d-flex align-center py-3" }, [
            _c(
              "div",
              { staticClass: "icon mr-2" },
              [
                _vm.documento.arquivos.length > 0
                  ? _c("v-icon", { attrs: { small: "", color: "green" } }, [
                      _vm._v("mdi-checkbox-marked-circle-outline"),
                    ])
                  : _c("v-icon", { attrs: { small: "", color: "grey" } }, [
                      _vm._v("mdi-information-outline"),
                    ]),
              ],
              1
            ),
            _c("div", { staticClass: "text-body-1" }, [
              _vm._v(" " + _vm._s(_vm.documento.titulo) + " "),
            ]),
          ])
        : _vm._e(),
      _c("v-divider"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }