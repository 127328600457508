<template>
  <div>
    <v-stepper v-model="e1">
    <!-- grupos de documentos -->
    <div class="categorias cursor-pointer" v-for="(item, i) in param.dadosDocumentos.tiposDocumentos" :key="i">
    </div>
    <!--modal com  lista de documentos, por grupo -->
    <div>
      <p class="primary--text text-h6 font-weight-regular">Veja abaixo a relação de documentos que podem comprovar a <strong>{{ grupoAtivo.grupo }}</strong>: </p>
      <div class="lista">
        <div class="item" v-for="(documento, index) in grupoAtivo.documentos" :key="index">
          <div class="d-flex align-center py-3" v-if="documento.visivel">
            <div class="icon mr-2">
              <v-icon small color="grey">mdi-information-outline</v-icon>
            </div>
            <div class="text-body-1">
              {{ documento.titulo }}
            </div>
          </div>
          <v-divider></v-divider>
        </div>
      </div>
      <v-row>
        <v-col>
          <v-btn depressed color="primary" class="secundaryBtn--text mt-3" @click="dialog = true">
            Anexar documento +
          </v-btn>
          <div class="text-caption mt-2">
            Documentos aceitos: png, jpg, jpeg, pdf<br/>
            Tamanho máximo: 5MB
          </div>
        </v-col>
      </v-row>
    </div>
    </v-stepper>
    <v-dialog
        v-model="dialog"
        max-width="500px"
        color="white"
      >
        <v-card>
          <v-card-title class="text-h6">
            Selecione o documento que será anexado
          </v-card-title>
          <v-card-text class="lista">
          <div class="item " v-for="(documento, index) in grupoAtivo.documentos" :key="index" @click="openUpload">
            <div class="d-flex align-center align-center justify-space-between cursor-pointer py-2" v-if="documento.visivel">
              <div class="text-body-1">
                {{ documento.titulo }}
              </div>
              <v-icon
                width="20"
                class="mx-2"
                color="grey darken-4"
                medium
                right>
                  mdi-chevron-right
                </v-icon>
            </div>
            <v-divider></v-divider>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <input type="file" accept="image/*,application/pdf" ref="fileUpload" v-show="false" @change="handleUpload">
  </div>
</template>

<script>
import NavegacaoGrupo from '@/components/documentos/NavegacaoGrupo.vue';

export default {
  // name:   ''
  data() {
    return {
      dialog: {
        step: 1,
        show: false,
      },
      grupoAtivo: this.param[0],
    };
  },
  props: {
    param: Object,
  },
  watch: {
    param: {
      handler(value) {
        this.grupoAtivo = { ...value.dadosDocumentos.tiposDocumentos[0] };
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    openUpload() {
      this.$refs.fileUpload.value = '';
      this.$refs.fileUpload.click();
    },
  },
};
</script>
