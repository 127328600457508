import api from '@/services/api';
import AppError from '@/utils/appError';

const resource = 'regras';
const buscaRegraRg = async ({ operadora, idade, anoNascimento }) => {
  try {
    const body = {
      operadora,
      idade,
      anoNascimento,
    };
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_REGRAS_API_KEY } };
    const { data } = await api.post(`${resource}/campos/buscar`, body, config);

    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const verificaRegraPis = async ({ entidade, cpf }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_REGRAS_API_KEY } };
    const { data } = await api.get(`${resource}/verifica-obrigatoriedade/${entidade}/${cpf}`, config);
    return data.data || {};
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const listarOrigensVenda = async () => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_REGRAS_API_KEY } };
    const { data } = await api.get(`${resource}/origem-venda`, config);
    return data.data || {};
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

export default {
  buscaRegraRg,
  verificaRegraPis,
  listarOrigensVenda,
};
