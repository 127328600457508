<template>
  <div>
    <v-app-bar color="primary" dark fixed>
      <v-container class="d-flex align-center pa-0">
        <v-btn
          v-if="step === 'inicio'"
          class="mr-3"
          small
          icon
          dark
          @click="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-btn v-else class="mr-3" small icon dark @click="step = 'inicio'">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-toolbar-title class="mt-1">
          <div>
            <h1 class="title font-weight-bold mb-1" style="line-height: 1">
              {{exibirTitulo}}
            </h1>
            <h3 class="caption ma-0" style="line-height: 1">
              {{ param.beneficiario.nome }}
            </h3>
          </div>
        </v-toolbar-title>
      </v-container>
    </v-app-bar>

    <div class="header-height"></div>
    <v-container class="pa-5 pt-0">
      <v-stepper v-model="step" class="elevation-0">
        <v-stepper-items>
          <v-stepper-content step="inicio">
            <div
              class="categorias cursor-pointer"
              v-for="(item, i) in param.dadosDocumentos.tiposDocumentos"
              :key="i"
              @click="mostraDocumentos(i)"
            >
              <navegacao-grupo :item="item" />
            </div>
          </v-stepper-content>
          <v-stepper-content step="detalhes">
            <p
              class="primary--text text-h6 font-weight-regular"
              v-if="grupoAtivo.grupo"
            >
              <span v-if="!grupoAtivo.habilitadoUpload"
                >Veja os documentos relacionados a
                <strong>{{ grupoAtivo.grupo }}</strong></span
              >
              <span
                v-else-if="
                  grupoAtivo.grupo === 'Elegibilidade' ||
                  grupoAtivo.grupo.indexOf('Legal') > -1
                "
              >
                Veja abaixo a relação de documentos que podem comprovar a
                <strong>{{ grupoAtivo.grupo }}</strong>
              </span>
              <span v-else
                >Veja quais os documentos de
                <strong>{{ grupoAtivo.grupo }}</strong> que podem ser
                anexados:</span
              >
            </p>
            <div
              class="item"
              v-for="(documento, index) in grupoAtivo.documentos"
              :key="index"
            >
              <detalhes-documentos :documento="documento" />
            </div>
            <v-row>
              <v-col
                v-if="
                  grupoAtivo.grupo != null &&
                  grupoAtivo.habilitadoUpload &&
                  param.anexarArquivos
                "
              >
                <v-btn
                  depressed
                  color="primary"
                  class="mt-3 secundaryBtn--text"
                  @click="validaUpload"
                  :disabled="isDisableButtonAnexarDocumento"
                >
                  Anexar documento +
                </v-btn>
                <div class="text-caption mt-2">
                  Documentos aceitos: png, jpg, jpeg, pdf<br />
                  Tamanho máximo: 5MB
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="grupoAtivo !== null">
                <arquivos
                  :items.sync="grupoAtivo"
                  :showBotao="param.showBotao"
                  :excluirArquivos="
                    grupoAtivo.habilitadoUpload &&
                    this.param.etapaProposta !== 'aguardando_aceite_anexos' &&
                    this.param.etapaProposta !== 'aguardando_assinatura_adendo'"
                  @excluirDocumento="excluir"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-btn
                  depressed
                  color="primary"
                  class="mt-3 secundaryBtn--text"
                  @click="step = 'inicio'"
                  block
                  >salvar</v-btn
                >
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <v-dialog v-model="dialog.show" max-width="500px">
        <v-card>
          <v-card-title class="text-h6">
            Selecione o documento que será anexado:
          </v-card-title>
          <v-card-text class="lista">
            <div
              class="item"
              v-for="(documento, index) in grupoAtivo.documentos"
              :key="index"
              @click="openUpload(index)"
            >
              <div
                class="d-flex align-center align-center justify-space-between cursor-pointer py-2"
                v-if="documento.visivel"
              >
                <div class="text-body-1">
                  {{ documento.titulo }}
                </div>
                <v-icon
                  width="20"
                  class="mx-2"
                  color="grey darken-4"
                  medium
                  right
                >
                  mdi-chevron-right
                </v-icon>
              </div>
              <v-divider></v-divider>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialog.preview"
        :width="isMobile ? '100%' : '600px'"
        scrollable
        persistent
      >
        <v-card>
          <div class="pt-5 pl-8 pr-8 pb-5">
            <v-row class="d-flex align-center align-center justify-space-between cursor-pointer">
              <v-icon @click="resetEdit()">mdi-undo-variant</v-icon>
                    <v-icon @click="rotate()">mdi-crop-rotate</v-icon>
              <v-icon @click="enabledCrop()">mdi-crop</v-icon>
            </v-row>
          </div>
          <v-card-text class="lista">
            <div
              class="d-flex align-center align-center justify-space-between cursor-pointer py-2"
              v-if="imagemBase64"
            >
              <!-- <v-image-input
                v-model="imagemBase64"
                :imageHeight="imageLoaded.height"
                :imageWidth="imageLoaded.width"
                :image-quality="1"
                :imageMinScaling="'contain'"
              /> -->
              <vue-cropper
                ref="cropper"
                :viewMode="0"
                :src="imagemBase64"
                :autoCrop="isMobile ? true : false"
                @ready="initCropper()"
              />
            </div>
            <!-- <v-divider></v-divider>
            <div
              class="d-flex align-center justify-space-between categoria py-0"
            >
              <v-btn
                type="input"
                color="primary"
                class="mt-3"
                @click="cancelarEnvio"
                >Cancelar</v-btn
              >
              <v-btn
                type="input"
                color="primary"
                class="mt-3"
                @click="salvarImagem"
                >Salvar</v-btn
              >
            </div> -->
          </v-card-text>
          <v-card-actions>
            <v-col class="6">
              <v-btn
                type="input"
                color="error"
                class="mt-3"
                block
                @click="cancelarEnvio"
                >Cancelar</v-btn
              >
            </v-col>
            <v-col class="6">
              <v-btn
                type="input"
                color="success"
                class="mt-3"
                block
                @click="salvarImagem"
                >Salvar</v-btn
              >
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <ManipulatePDF
        v-if="dialog.pdf"
        :dialog="dialog.pdf"
        :base64="src"
        @savefile="salvarArquivo('pdf', $event)"
        @hidedialog="
          dialog.pdf = false;
          src = '';
        "
      />

      <input
        type="file"
        accept="image/*,application/pdf"
        ref="fileUpload"
        v-show="false"
        @change="handleUpload"
      />
    </v-container>

    <base-dialog-details-file-type
      :titulo="tituloModal"
      :dialog="dialogDetalhes"
      @closedialog="dialogDetalhes = false"
    />
    <v-snackbar v-model="updating.show" timeout="-1">
      <v-progress-circular
        indeterminate
        color="white"
        size="20"
        class="mr-3"
        width="2"
      ></v-progress-circular>
      {{ updating.mensagem }}
    </v-snackbar>
  
    <v-dialog v-model="showModalCamera" max-width="500">
      <v-card>
        <v-card-title>
          <b>Qual método deseja usar?</b>
        </v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="auto">
              <v-btn class="secundaryBtn--text" color="primary" @click="selecionarMetodoUpload('upload')">Selecionar arquivo</v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn class="secundaryBtn--text" color="primary" @click="selecionarMetodoUpload('camera')">Câmera</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>


    <div v-if="mostrandoCamera" class="camera-options">
      <v-row justify="center">
        <v-col cols="auto">
          <v-btn color="primary" class="py-6 secundaryBtn--text" :rounded="true" @click="fecharCamera()"><v-icon size="50" color="white">mdi-arrow-left</v-icon></v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn color="primary" class="py-6 secundaryBtn--text" :rounded="true" @click="capturarCamera()"><v-icon size="50" color="white">mdi-camera-iris</v-icon></v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn color="primary" class="py-6 secundaryBtn--text" :rounded="true"  @click="flipCamera()"><v-icon size="50" color="white">mdi-camera-flip-outline</v-icon></v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// import VImageInput from "vuetify-image-input";
import documentoService from "@/services/documentoService";
import BaseDialogDetailsFileType from "@/components/BaseDialogDetailsFileType.vue";
import BaseContentDocumentos from "@/components/BaseContentDocumentos.vue";
import NavegacaoGrupo from "@/components/documentos/NavegacaoGrupo.vue";
import DetalhesDocumentos from "@/components/documentos/DetalhesDocumentos.vue";
import Arquivos from "@/components/documentos/Arquivos.vue";
import ManipulatePDF from "@/components/documentos/ManipulatePDF.vue";
import { mapState } from "vuex";
import VueCropper from "vue-cropperjs";
import { Capacitor } from '@capacitor/core'
import { CameraPreview } from '@capacitor-community/camera-preview';
import { Camera } from '@capacitor/camera';
import { App } from '@capacitor/app';

export default {
  components: {
    Arquivos,
    BaseDialogDetailsFileType,
    NavegacaoGrupo,
    DetalhesDocumentos,
    // VImageInput,
    ManipulatePDF,
    VueCropper,
  },
  props: {
    param: Object,
  },
  data() {
    return {
      src: "",
      page: 1,
      imagemBase64: null,
      ativoIndex: null,
      grupoAtivo: {},
      tipoDocumento: null,
      step: "inicio",
      updating: {
        show: false,
        mensagem: "",
      },
      dialog: {
        preview: false,
        show: false,
        pdf: false,
      },
      imageLoaded: {
        width: 700,
        height: 315,
      },
      loadingDocs: false,
      panel: [],
      documentos: [],
      tituloModal: "",
      dialogDetalhes: false,
      showModalCamera: false,
      mostrandoCamera: false,
    };
  },
  watch: {
    param(value) {
      if (this.ativoIndex !== null) {
        this.mostraDocumentos(this.ativoIndex);
        this.updating.show = false;
      }
    },
    immediate: true,
    deep: true,
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.user.isMobile,
      propostaState: (state) => state.cadastroProposta.proposta,
    }),
    isDisableButtonAnexarDocumento() {
      return this.propostaState.etapa === 'aguardando_assinatura' && typeof this.propostaState.permiteEditar === 'boolean' &&  !this.propostaState.permiteEditar
    },
    exibirTitulo() {
      const titulo = this.param.dadosDocumentos.tipoBeneficiario;
      switch (titulo) {
      case "RESPONSAVEL_LEGAL":
        return "Responsável Legal";
      case "TITULAR":
        return "Titular";
      case "DEPENDENTE":
        return "Dependente";
      default:
        return titulo;
      }
    },
  },
  methods: {
    closeDialog() {
      this.$emit("closedialog");
      this.ativoIndex = null;
    },
    openModalDetalhes(titulo) {
      this.tituloModal = titulo;
      this.dialogDetalhes = true;
    },
    mostraDocumentos(index) {
      this.ativoIndex = index;
      this.grupoAtivo = this.param.dadosDocumentos.tiposDocumentos[index];
      this.step = "detalhes";
    },
    openUpload(tipo) {
      this.tipoDocumento = tipo;
      if(Capacitor.isNativePlatform() && Capacitor.platform === 'android') {
        this.showModalCamera = true;
      } else {
        this.$refs.fileUpload.value = "";
        this.$refs.fileUpload.click();
      }
    },
    async selecionarMetodoUpload(resposta) {
      if(resposta === "upload") {
        this.$refs.fileUpload.value = "";
        this.$refs.fileUpload.click();
      } else {
        try {
          window.screen.orientation.lock('portrait');
          App.addListener('backButton', () => {
            this.fecharCamera();
          });
          const camera = await CameraPreview.start({
            position: 'rear',
            paddingBottom: 70
          });
          this.mostrandoCamera = true;
        } catch (error) {
          App.removeAllListeners();
          const permissaoCamera = await Camera.checkPermissions();
          if(permissaoCamera.camera === 'denied') {
            alert('Permissão de câmera negada pelo usuário');
          }
          window.screen.orientation.unlock();
          this.mostrandoCamera = false;
        }
        this.dialog.show = false;
      }
      this.showModalCamera = false;
    },
    async fecharCamera() {
      window.screen.orientation.unlock();
      CameraPreview.stop();
      this.mostrandoCamera = false;
      App.removeAllListeners();
    },
    async capturarCamera() {
      const image = await CameraPreview.capture({quality: 100});
      this.fecharCamera();
      console.log(image);
      this.handleUploadApp(`data:image/jpeg;base64,${image.value}`);
    },
    async flipCamera() {
      CameraPreview.flip();
    },
    validaUpload(dialog) {
      if (this.param.etapaProposta === 'aguardando_aceite_anexos') {
        this.$root.$snackBar.open({
          color: 'error',
          message: 'Existe uma assinatura de anexo pendente, por favor, finalize antes de inserir novos.',
        });

        return;
      }

      if (this.param.etapaProposta === 'aguardando_assinatura_adendo') {
        this.$root.$snackBar.open({
          color: 'error',
          message: 'Existe uma assinatura de adendo pendente, por favor, finalize antes de inserir novos arquivos.',
        });

        return;
      }

      if (this.param.etapaProposta === 'adendo_assinado') {
        this.$root.$snackBar.open({
          color: 'error',
          message: 'Não é possível inserir arquivos em um adendo assinado.',
        });

        return;
      }

      this.dialog.show = true;
    },
    async imageBase(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = async () => resolve(reader.result);
        reader.readAsDataURL(file);
      });
    },
    async getDimensionsImage(base64) {
      return new Promise((resolve) => {
        const image = new Image();
        image.src = base64;
        // eslint-disable-next-line func-names
        image.onload = function () {
          resolve({
            width: this.width,
            height: this.height,
          });
        };
      });
    },
    handleValidFile(ext) {
      const extensions = ["png", "pdf", "jpg", "jpeg"];
      if (!extensions.some((x) => x.toLowerCase() === ext)) {
        return false;
      }
      return true;
    },
    handleFileSize(bytes) {
      const megabytes = (bytes / 1024) / 1024;
      return megabytes <= 5;
    },
    encontrarTamanhoBase64(base64String) {
      const stringLength = base64String.length - 'data:image/png;base64,'.length;

      const sizeInBytes = 4 * Math.ceil((stringLength / 3))*0.5624896334383812;
      return sizeInBytes;
    },
    async handleUpload(event) {
      if (event) {
        const [file] = event.target.files;
        const extensao = file.name.split(".").pop();
        const isInvalid = await this.handleValidFile(extensao);
        if (!isInvalid) {
          this.$root.$snackBar.open({
            color: "error",
            message: "Tipo de arquivo inválido",
          });
          return;
        }
        const isValidSize = this.handleFileSize(file.size);
        if (!isValidSize) {
          this.$root.$snackBar.open({
            color: "error",
            message: "Arquivo excedeu o tamanho de 5MB",
          });
          return;
        }
        const stream = await this.imageBase(file);
        await this.finalizaUpload({extensao, stream});
      }
    },
    async handleUploadApp(stream) {
      const arrayBase64 = stream.match("/(.*);");
      const extensao = arrayBase64[1];
      const isInvalid = await this.handleValidFile(extensao);
      const isValidSize = this.handleFileSize(this.encontrarTamanhoBase64(stream));

      if (!isInvalid) {
        this.$root.$snackBar.open({
          color: "error",
          message: "Tipo de arquivo inválido",
        });
        return;
      }
      
      if (!isValidSize) {
        this.$root.$snackBar.open({
          color: "error",
          message: "Arquivo excedeu o tamanho de 5MB",
        });
        return;
      }

      await this.finalizaUpload({extensao, stream});
    },
    async finalizaUpload({extensao, stream}) {
        if ((extensao || "").toLowerCase() === "pdf") {
          this.src = stream;
          this.dialog.show = false;
          this.dialog.pdf = true;
          return;
        }
        const { width, height } = await this.getDimensionsImage(stream);
        this.imageLoaded.width = width;
        this.imageLoaded.height = height;
        this.imagemBase64 = null;
        setTimeout(() => {
          this.imagemBase64 = stream;
          this.dialog.preview = true;
          // if (!this.isMobile) {
          //   this.dialog.preview = true;
          // } else {
          //   this.salvarImagem();
          // }
        }, 100);
    },
    async salvarArquivo(extensao, { stream, pagesSelected }) {
      this.updating.mensagem = "Salvando arquivo...";
      this.updating.show = true;
      this.dialog.show = false;
      this.dialog.preview = false;
      extensao = `.${extensao}`;
      const arquivos = [
        {
          stream,
          extensao,
          observacao: "",
        },
      ];
      try {
        const result = await documentoService.adicionarDocumento({
          guidProposta: this.param.guidProposta,
          guidTipoDocumento: this.grupoAtivo.documentos[this.tipoDocumento]
            .guid,
          arquivos,
          pagesSelected,
        });
        if (result) this.$emit("update", true);
      } catch (error) {
        this.updating.show = false;
        this.$root.$snackBar.open({
          color: 'error',
          message: error.message,
        });
      }
    },
    async salvarImagem() {
      // this.$refs.cropper.getCroppedCanvas();
      // this.$refs.cropper.getCroppedCanvas({
      //   maxWidth: 1200,
      //   maxHeight: 1200,
      //   fillColor: '#fff',
      //   imageSmoothingEnabled: false,
      //   imageSmoothingQuality: 'high',
      // });
      const dataBase64 = this.$refs.cropper.getCroppedCanvas().toDataURL();
      const extensao = this.getExtension(dataBase64);
      const stream = dataBase64.split(",").pop();
      await this.salvarArquivo(extensao, { stream });
    },
    async cancelarEnvio() {
      // Remove a imagem anterior
      this.imagemBase64 = null;
      this.dialog.preview = false;
    },
    async excluir(guid) {
      this.updating.mensagem = "Removendo arquivo...";
      this.updating.show = true;
      // param.beneficiario.guid
      const arquivos = [guid];
      try {
        const result = await documentoService.excluirDocumento({
          guidProposta: this.param.guidProposta,
          guidParticipante: this.param.beneficiario.guid,
          arquivos,
        });
        if (result) this.$emit("update", true);
      } catch (error) {
        this.updating.show = false;
        this.$root.$snackBar.open({
          color: 'error',
          message: error.message,
        });
      }
    },
    getExtension(base64) {
      const urlFotoCopia = base64.slice();
      const arrExt = ["png", "jpg", "jpeg"];
      const ext = urlFotoCopia.substring(
        urlFotoCopia.indexOf("/") + 1,
        urlFotoCopia.indexOf(";"),
      );
      if (!arrExt.includes(ext)) {
        throw new Error("Extensão inválida");
      }
      return ext;
    },
    initCropper() {
      const boxData = this.$refs.cropper.getImageData();
      if (boxData.width < 300) {
        this.$refs.cropper.zoomTo(1);
      } else {
        this.$refs.cropper.setCanvasData({
          width: 300,
          height: 300,
          left: this.isMobile ? 0 : 100,
          top: 0,
        });
        this.$refs.cropper.setCropBoxData({
          width: 300,
          height: 300,
          left: this.isMobile ? 0 : 100,
          top: 0,
        });
      }

      if (this.isMobile) {
        this.$refs.cropper.setCropBoxData({
          width: boxData.width,
          height: boxData.height,
        });
      }
    },
    resetEdit() {
      this.$refs.cropper.clear();
    },
    rotate() {
      this.$refs.cropper.rotate(90);
    },
    enabledCrop() {
      const boxData = this.$refs.cropper.getImageData();
      this.$refs.cropper.setCropBoxData({
        width: boxData.width, height: boxData.height,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header-height {
  padding: 20px;
  height: 56px;
  @media (min-width: 960px) {
    height: 70px;
  }
}
.camera-options {
  background-color: rgb(170, 170, 170);
  position:fixed;
  bottom: 0;
  width:100%;
  height: 70px;
  z-index: 99999;
  padding: 10px
}
</style>
