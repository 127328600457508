<template>
  <div>
    <v-container class="pa-5 pb-5 pt-10">
      <div class="text-center mt-5">
        <v-icon color="error" style="font-size: 120px"
          >mdi-alert-circle</v-icon
        >
      </div>
    </v-container>

    <v-container class="pa-5 pb-5 pt-5">
      <v-expansion-panels v-model="panel" multiple>
        <v-expansion-panel class="mb-5">
          <v-expansion-panel-header>
            <h1
              class="title font-weight-regular mt-4 mb-1 primary--text"
              style="line-height: 1"
            >
              Pendências ({{ pendenciasPreenchimento.length }})
            </h1>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
               <span v-if="pendenciasPreenchimento && pendenciasPreenchimento.length == 0" class="primary--text">Nenhum item encontrado</span>
            <div
              class="d-flex border-top pt-4 pb-4"
              v-for="(item, i) in pendenciasPreenchimento"
              :key="i"
            >
              <v-icon color="amber" large>mdi-alert-circle</v-icon>
              <span class="ml-5 pt-2 primary--text">{{ item.mensagem }}</span>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h1
              class="title font-weight-regular mt-4 mb-1 primary--text"
              style="line-height: 1"
            >
              Erros ({{ erros.length }})
            </h1>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <span v-if="erros && erros.length == 0" class="primary--text">Nenhum item encontrado</span>
            <div
              class="d-flex border-top pt-4 pb-4"
              v-for="(item, i) in erros"
              :key="i"
            >
              <v-icon color="red darken-4" large>mdi-close-circle</v-icon>
              <span class="ml-5 pt-2 primary--text">{{ item.mensagem }}</span>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    pendenciasPreenchimento: Array,
    erros: Array,
  },
  data() {
    return {
      panel: [],
      itens: 2,
    };
  },
  mounted() {
    this.expandirTodos();
  },
  methods: {
    expandirTodos() {
      this.panel = [...Array(this.itens).keys()].map((k, i) => i);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
